const firebaseConfig = {
  apiKey: 'AIzaSyCxAl3PEsADBQ0I10-tkwfV33KAFnLyN74',
  authDomain: 'supplycaravan-staging.firebaseapp.com',
  projectId: 'supplycaravan-staging',
  storageBucket: 'supplycaravan-staging.appspot.com',
  messagingSenderId: '829905424928',
  appId: '1:829905424928:web:42e72e22b2893db7196245',
};

export default firebaseConfig;
